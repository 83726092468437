import React from "react";
import "../css/components/carousel-component.css";
class CarouselComponent extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            current: 0,
            autoPlay: false
        }
        this.timeOut = null;
    }

    componentDidMount() {
        this.timeOut = setInterval(this.slideRight, 2000);
    }

    componentWillUnmount() {
        clearInterval(this.timeOut);
    }

    slideRight = () => {
        this.setState((prevState) => ({
            current: prevState.current === this.props.images.length - 1 ? 0 : prevState.current + 1,
        }))
    };

    slideLeft = () => {
        this.setState((prevState) => ({
            current: prevState.current === 0 ? this.props.images.length - 1 : prevState.current - 1,
        }));
    };

    handleMouseEnter = () => {
        this.setState({ autoPlay: false });
        clearInterval(this.timeOut);
    };

    handleMouseLeave = () => {
        this.setState({ autoPlay: true });
        this.timeOut = setInterval(this.slideRight, 2000);
    };

    render() {
        const { images } = this.props;
        const { current } = this.state;

        return (
            <div
                className="carousel"
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
            >
                <div className="carousel_wrapper">
                    {images.map((image, index) => (
                        <div
                            key={index}
                            className={`carousel_card ${index === current ? 'carousel_card-active' : ''}`}
                        >
                            <img className="card_image" src={image.image} alt={""}/>
                        </div>
                    ))}
                    <div className="carousel_pagination">
                        {images.map((_, index) => (
                            <div
                                key={index}
                                className={`pagination_dot ${index === current ? 'pagination_dot-active' : ''}`}
                                onClick={() => this.setState({ current: index })}
                            ></div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

export default CarouselComponent;