import React, { useEffect, useState } from "react";
import "../../css/routes/calendario.css";
import { useLoaderData } from "react-router-dom";
// import PlayCardEvents from '../../components/play-cardEvents';
import FirebaseService from "../../services/firebase-service";
import PlayCardEvents from "../../components/play-cardEvents";

export async function loader() {
  const plays = await FirebaseService.listCollection("calendario");
  return { plays };
}

const CalendarioPage = () => {
  const d = new Date();
  let currentMonth = d.getMonth();

  const { plays } = useLoaderData();
  const presentes = plays.plays.filter((play) => {
    if (currentMonth === play.mes) return play.mes;
  });
  const futuras = plays.plays.filter((play) => {
    if (currentMonth <= play.mes && !(currentMonth === play.mes))
      return play.mes;
  });
  // No need for names array as JSX can directly map over objects
  return (
    <div className="creaciones-page calendario-page">
      <h1>Programacion</h1>
      <div className="card-container">
        <h1>PARA ESTE MES</h1>
        <div className="card-container">
          {Object.values(presentes).map((play) => {
            // const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
            // const name = month[play.mes];
            // const raw_name = "Calendario/" + name.replace(/[\:\"\?\/]/gi, "");
            const raw_name = "Calendario/"+play.title.replace(/[\:\"\?\/]/gi, '');
            return (
              <PlayCardEvents
                key={play.play} // Key should be based on unique play identifier
                title={play.title}
                title_raw={raw_name}
                description={play.descripcion}
                fecha_final={play.fecha_final}
                fecha_inicial={play.fecha_inicial}
                mes={play.mes}
                year={play.year}
                id={play.id}
                direccion={play.direccion}
                espacio={play.espacio}
                ubicacion={play.ubicacion}
              />
            );
          })}
        </div>
        <h1>Funciones futuras</h1>
        <div className="card-container">
          {Object.values(futuras).map((play) => {
            // const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
            // const name = month[play.mes];
            // const raw_name = "Calendario/" + name.replace(/[\:\"\?\/]/gi, "");
            const raw_name = "Calendario/"+play.title.replace(/[\:\"\?\/]/gi, '');
            return (
              <PlayCardEvents
                key={play.play} // Key should be based on unique play identifier
                title={play.title}
                title_raw={raw_name}
                description={play.descripcion}
                fecha_final={play.fecha_final}
                fecha_inicial={play.fecha_inicial}
                year={play.year}
                mes={play.mes}
                id={play.id}
                direccion={play.direccion}
                espacio={play.espacio}
                ubicacion={play.ubicacion}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CalendarioPage;
