import React, { useState, useEffect } from 'react';
import '../css/routes/group-page.css';
import {collection, getDocs, orderBy, query} from "firebase/firestore";
import {fireStore, firebaseStorage} from "../config/firebase-config";
import PersonItem from "../components/person-item";
const GrupoPage = () => {

    const [data, setData] = useState([]);

    useEffect(() => {
        const getData = async () => {
            const collectionRef = collection(fireStore, 'nosotros');

            try {
                var playsReference = query(collectionRef, orderBy("idx", "asc"));
                const querySnapshot = await getDocs(playsReference);



                const docs = querySnapshot.docs.map((doc) => {
                    var data = doc.data();
                    return <PersonItem
                        nombre={data.nombre}
                        cargo={data.cargo}
                        img={data.img}
                    />
                });
                setData(docs);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        getData();
    }, []); // Empty dependency array: fetch data only once on mount



    return (
        <div className="grupo-page">
            <h1 id={"Title"}>
                Grupo
            </h1>
            <p id={"paragraph"}>

                El Teatro Tierra es un grupo independiente y  profesional que ha permanecido en el tiempo creando de manera constante. Funciona como una compañía alternativa que realiza montajes, temporadas y giras y como un laboratorio de investigación donde se trabaja sobre el arte de la actuación y las estructuras del drama contemporáneo.
                <br/>
                <br/>
                Hoy el grupo esta conformado por un Director Artístico y Dramaturgo, Actrices, actores y músicos que hacen parte de los elencos de las obras que se encuentran en repertorio y una productora ejecutiva que se encarga de la producción y administración.
            </p>
            <div className={"cards"}>
                {data}
            </div>
        </div>
    );
}

export default GrupoPage;
