import React, {useState, useEffect} from 'react';
import ClavesIMG from '../../media/talleres/Siete Claves del Teatro  Mágico/Portada.jpg';
import trailer from '../../media/talleres/Siete Claves del Teatro  Mágico/trailer 7 claves.mp4';

import '../../css/routes/talleres.css';


import foto1 from '../../media/talleres/Siete Claves del Teatro  Mágico/Fotos/2K6A3290.jpg';
import foto2 from '../../media/talleres/Siete Claves del Teatro  Mágico/Fotos/2X8A2346.JPG';
import foto3 from '../../media/talleres/Siete Claves del Teatro  Mágico/Fotos/10.JPG';
import foto4 from '../../media/talleres/Siete Claves del Teatro  Mágico/Fotos/2X8A5748.JPG';
import foto5 from '../../media/talleres/Siete Claves del Teatro  Mágico/Fotos/2X8A8345.JPG';
import foto6 from '../../media/talleres/Siete Claves del Teatro  Mágico/Fotos/CL107455.jpg';

const TallerSieteClaves = () => {


    return (
        <div className="talleres-page">
            <h1 className={"title-ban"}>
                Siete claves del teatro magico
            </h1>

            <div className={"top-images"}>
                <img src={foto1}/>
                <img src={foto2}/>
                <img src={foto3}/>
                <img src={foto4}/>
                <img src={foto5}/>
                <img src={foto6}/>
            </div>

            <p style={{textAlign: "center", fontSize: "1.3vw", fontWeight:"bold"}}>
                Es un camino para aprender herramientas y ejercitar el conocimiento teatral. El concepto teatro mágico
                está asociado a la idea de pensamiento mágico, que ha emergido desde América Indígena y Mestiza.
            </p>
            <div className={"claves-container"}>
                <div className={"clave-item"} style={{backgroundColor: "#bf9100"}}>
                    <h1>Primera clave: El desarraigo.</h1>
                    <p>
                        Reconocimiento de limitaciones y tensiones. Luego, el desapego de esquemas que restringen la
                        función expresiva y minimizan el potencial interpretativo. Es un trabajo que consiste en
                        abandonar en la escena los hábitos cotidianos que suelen ser recurrentes y terminan siendo
                        impedimentos. Es el desarraigo de límites y la apertura de fuentes inéditas de percepción. El
                        encuentro con el escenario, con las verdaderas raíces.
                    </p>
                </div>
                <div className={"clave-item"} style={{backgroundColor: "#ff9934"}}>
                    <h1>Segunda Clave: La ecuación biológica.</h1>
                    <p>
                        El ritmo personal: el torrente sanguíneo como melodía detectable en las arterias carótidas y la
                        percusión que marca el corazón. Para actuar es indispensable conocer la propia magnitud rítmica,
                        los impulsos primordiales. Es la conciencia del pulso y el impulso, desde el ser biológico, en
                        la acción física, en reposo y movimiento. La plenitud expresiva pasa el autoconocimiento y la
                        ejercitación del ritmo personal.
                    </p>
                </div>
                <div className={"clave-item"} style={{backgroundColor: "#7f8000"}}>
                    <h1>Tercera clave: Unidad equilibrio-desequilibrio.</h1>
                    <p>
                        El equilibrio mínimo y el desequilibrio controlado para conocer ese estado donde se aprovechan
                        las fuerzas físicas de oposición para constituir la unidad armónica del cuerpo viviente. Un
                        punto entre el movimiento y la inmovilidad para obtener el máximo de expresión con el mínimo de
                        acción. El cuerpo humano como cantera de tensiones y distensiones, pesos y levedades, acciones y
                        reacciones, que al entrar en estado expresivo hacen orgánica la actuación.
                    </p>
                </div>

                <div className={"clave-item"} style={{backgroundColor: "#3b6583"}}>
                    <h1>Cuarta clave: El espacio interior. </h1>
                    <p>
                        El mundo interior es un laberinto desconocido, que preferimos evadir o recorrer con ojos
                        cerrados. Exploración interior que busca ampliar las proporciones expresivas. Se trata de
                        habitar el mundo interior y conseguir desplazarse por diversas áreas de la sensibilidad, desde
                        lo físico hasta lo subjetivo de las emociones y los sueños.
                    </p>
                </div>
                <div className={"clave-item"} style={{backgroundColor: "#96960c"}}>
                    <h1>Quinta clave: El espacio exterior. </h1>
                    <p>
                        Reconocimiento de planos escénicos, direcciones, topografías escénicas y funciones expresivas.
                        Fortalecimiento de la presencia, la plasticidad y la escucha o la capacidad de estar en relación
                        con los otros, con las situaciones y con el espacio. El escenario como universo y las acción
                        escénica como acontecimiento.
                    </p>
                </div>
                <div className={"clave-item"} style={{backgroundColor: "#c55b11"}}>
                    <h1>Sexta clave: La dimensión plástica del cuerpo. </h1>
                    <p>
                        Lenguaje de imágenes: la metáfora visual y la composición a partir de los cuerpos y los objetos.
                        El asunto es muscular y nervioso y dinamiza las sutiles conexiones entre el estiramiento y la
                        contracción, en el manejo de la espina dorsal y en la polivalencia del torso y las extremidades.
                        Diseños orgánicos, pictóricos, que le dan sentido a lo físico y le otorgan significado a los
                        juegos gesticos y corporales.
                    </p>
                </div>
                <div className={"clave-item"} style={{backgroundColor: "#676767"}}>
                    <h1>Séptima clave: La dimensión sonora.</h1>
                    <p>
                        El cuerpo como emisor y receptor de frecuencias sonoras, de lenguajes articulados y no
                        articulados, verbales y no verbales. Resuenan las cavidades corporales al ritmo de la
                        respiración y la columna de aire se convierte en acción física. La voz se integra al todo sonoro
                        de la escena. El silencio es el soporte de la sonoridad teatral. La voz proyecta el cuerpo más
                        allá de la piel. La dimensión sonora se acopla con la dimensión plástica y lo dramático se hace
                        denso, vibrante.
                    </p>
                </div>
            </div>
            <p style={{textAlign: "center", fontSize: "1.3vw", fontWeight:"bold"}}>
                Cada clave tiene un sistema de ejercicios que se han depurado con los años y las sesiones de trabajo.
                Cuando miramos el camino recorrido entendemos que es necesario afirmar las huellas de nuestro propio
                rumbo. Ofrecemos la curiosidad inicial.
            </p>
            <div className="trailer" style={{display:"flex", justifyContent:"center"}}>
                <video width={"50%"} controls style={{borderRadius:10}}>
                    <source src={trailer} type="video/mp4"/>
                </video>
            </div>
        </div>
    );
};

export default TallerSieteClaves;
