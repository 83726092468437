import React, { useState, useEffect } from 'react';
import ClavesIMG from '../../media/talleres/Siete Claves del Teatro  Mágico/Portada.jpg';
import MetamorfosisIMG from '../../media/talleres/Metamorfosis y Vuelo/Portada.jpg';

import '../../css/routes/talleres.css';
import {Link} from "react-router-dom";

const TalleresPage = () => {


    return (
        <div className="talleres-page">
            <h1 className={"title-ban"}>
                TALLERES TEATRALES
            </h1>
            <h4 style={{textAlign:"justify"}}>
                El Teatro Tierra ha forjado un camino escénico donde confluyen experiencias y saberes que han permitido realizar propuestas estéticas que se fundamentan en la actuación, el lenguaje de las imágenes visuales y la resemantización de los objetos. Para llegar a la poética dramática de diversos montajes, fue necesario asimilar herramientas y crear maneras de acceder a planteamientos coherentes con propósitos de investigación, formación y aprendizaje. Durante casi 40 años el Teatro Tierra ha construido un método y una estrategia práctica para fortalecer la función expresiva de los cuerpos, elaborando alfabetos físicos y plásticos desde la dimensión orgánica  de la actuación. Algo que tiene que ver con el quehacer de tablas, con los elementos de tradiciones que se renuevan y siguen siendo ejes de la teoría y la práctica teatral. En los talleres que realizamos condensamos conocimiento y trasmitimos el uso de instrumentos escénicos que permiten entender el Teatro como una labor y una poética de vida, donde la disciplina y la imaginación son fundamentos de búsquedas, experimentos y procesos que involucran saberes directos.
            </h4>
            <h1 style={{textAlign:"center", textTransform:"uppercase", margin: "3rem 0"}}>
                Los talleres de formación que ofrecemos son:
            </h1>
            <div>
                <div className={"taller-item"} style={{justifyContent: "start"}}>
                    <img
                        src={ClavesIMG}/>
                    <div style={{width: "20px"}}/>
                    <Link to={"/taller-siete-claves"}>
                        <h1>
                            Siete claves del teatro mágico
                        </h1>
                        <h5>
                            Técnica y alquimia del trabajo expresivo
                        </h5>
                    </Link>
                </div>
                <div className={"taller-item"} style={{justifyContent:"end"}}>
                    <Link to={"/taller-metamorfosis"}>
                        <h1>
                            Metamorfosis y vuelo
                        </h1>
                        <h5>
                            Taller de Creación Teatral
                        </h5>
                    </Link>
                    <div style={{width: "20px"}}/>

                    <img
                        src={MetamorfosisIMG}/>
                </div>
            </div>
        </div>
    );
};

export default TalleresPage;
