import React, { useState, useEffect } from 'react';
import Logo from "../media/Logo Transparente.png";
import img1 from "../media/sede/Entrada1.jpg";
import img2 from "../media/sede/Escenario1.jpg";
import img3 from "../media/sede/Escenario.jpg";
import img4 from "../media/sede/Salón de Música.jpg";

import '../css/routes/sede.css';

const PublicacionesPage = () => {


    return (
        <div className="sede-page">
            <h1>
                SEDE DEL TEATRO TIERRA
            </h1>
            <div className={"info-columns"}>
                <div className={"left-info column-item"}>
                    <h2>
                        Espacio no Convencional
                    </h2>
                    <h4 style={{textAlign: "center", fontWeight: "bold"}}>
                        "Un espacio de creación, formación y persistencia"
                    </h4>
                    <h4 style={{textAlign: "center", fontWeight: "normal"}}>
                        Calle 31 # 4 - 27
                        <br/>
                        Barrio La Perseverancia
                        <br/>
                        Localidad Santa Fe
                        <br/>
                        Bogotá, D.C. - Colombia
                    </h4>
                    <img src={Logo} alt={"TeatroTierra logo"}/>
                </div>
                <div className={"column-item"}>
                    <p>
                        Uno de los motivos del Teatro Tierra para fundar una sede estable, donde se desarrollan procesos
                        creativos y formativos, es darle continuidad a la idea de afianzar una tradición que se renueva
                        con los jóvenes y las personas que optan por el teatro como forma de vida y profesión esencial.
                        Después de peregrinar por locales distintos decidimos echar raíces en una vieja casa del barrio
                        La Perseverancia, donde hemos trabajado durante años creando obras y proyectando calidad
                        escénica y calidez artística, porque el teatro es un arte de humanísimas resonancias.
                    </p>
                    <img src={img1} alt={""}/>
                </div>
                <div className={"column-item"}>
                    <img src={img2} alt={""}/>
                    <p>
                        Es el lugar donde nos encontramos todos los días y donde rendimos homenaje a nuestros ancestros
                        del teatro que nos enseñaron a no declinar, a tener dedicación, a entregar la vida y la
                        capacidad de ser, para manifestar lo que de otra manera quizá no podríamos expresar. De ahí que
                        nuestro árbol tenga las raíces al cielo porque la imaginación es imprescindible a la hora de
                        crear. Siendo Teatro Tierra pensamos que los sueños deben florecer libremente. Una obra es un
                        proceso terrenal y emotivo donde lo poético se encuentra más allá de lo evidente.
                    </p>
                </div>
            </div>
            <div className={"info-columns"}>
                <div className={"column-item"}>
                    <p>
                        Por ahora estamos ensayando, montando las nuevas obras, haciendo talleres de formación para gente de teatro y para espectadores, pues el público siempre ha sido nuestro fiel aliado. Pensamos convertirnos en un epicentro de acción teatral, que se proyecte en ondas concéntricas, hacia la comunidad local, la ciudad, el país, el continente y los escenarios del mundo, porque el teatro es universal y también terrenal, objetivo, necesario, alimento para los espíritus y los cuerpos. Hemos avanzado en la construcción del lugar pero aún nos faltan aspectos fundamentales, que estamos resolviendo con nuestro trabajo, las instituciones y la gente que nos apoya.
                    </p>
                    <img src={img3} alt={""}/>
                </div>
                <div className={"column-item"}>
                    <img src={img4} alt={""}/>
                    <p>
                        Nuestra casa está situada en las siguientes coordenadas: Latitud 04°36′35″Norte y Longitud
                        74°04′54″Oeste, a 2621 metros sobre el nivel del mar, en la parte central de Bogotá, arriba de
                        la zona internacional, en la calle 31 donde lindan los barrios Macarena y Perseverancia, en el
                        número 4 – 27 donde respira el teatro y transpiran los cuerpos trabajando, creando, creyendo en
                        el arte, propiciando el encuentro entre la trascendencia del instante y lo efímero de la vida.
                        Es una casa en construcción, un proyecto que despega, un punto de contacto en la tierra del
                        teatro.
                    </p>
                </div>
                <div className={"left-info column-item"} style={{justifyContent:"center"}}>
                    <img src={Logo} alt={"TeatroTierra logo"}/>
                </div>
            </div>
        </div>
    );
};

export default PublicacionesPage;
