// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage"
import { getFirestore } from "firebase/firestore"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDkIcA4UErKd6BvUh68eWqrC8eAKRunEcc",
    authDomain: "teatrotierra-50733.firebaseapp.com",
    databaseURL: "https://teatrotierra-50733-default-rtdb.firebaseio.com",
    projectId: "teatrotierra-50733",
    storageBucket: "teatrotierra-50733.appspot.com",
    messagingSenderId: "344946617200",
    appId: "1:344946617200:web:fc9a54554ac7523b147db5",
    measurementId: "G-SB4LQ5MR6Z"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);

export const fireStore = getFirestore(app);
export const firebaseStorage = getStorage(app);