import {fireStore, firebaseStorage} from "../config/firebase-config";
import {collection, onSnapshot, orderBy, query, getDocs, getDoc, doc, limit} from "firebase/firestore";
import {getDownloadURL, ref, listAll, list} from "firebase/storage";

export default class FirebaseService{

    static async listCollection(collectionName, limitNumber){
        let playsReference = collection(fireStore, collectionName);
        if( limitNumber && typeof limitNumber === 'number' ){
            playsReference = query(playsReference, orderBy("year", "desc"), limit(limitNumber));
        }
        else if (collectionName === 'calendario'){
            playsReference = query(playsReference, orderBy("fecha_inicial", "asc"))
        }
        else {
            playsReference = query(playsReference, orderBy("year", "desc"));
        }
        let plays;
        await getDocs(playsReference).then(
            data => {
                plays = data.docs.map((doc) => {
                    const data = doc.data();
                    data["id"] = doc.id;
                    return {...data};
                })
            }
        )
        // console.log(plays)
        return { plays: plays };
    }

    static async getPoster( play_name, path = "" ){
        try{
            const folder_ref = ref(firebaseStorage, `teatrotierra/${play_name}/Portada.jpg`);
            return await getDownloadURL(folder_ref);
        }
        catch (error){

        }
        return "";
    }

    static async getTrailer(play_name){
        try{
            const folder_ref = ref(firebaseStorage, `teatrotierra/${play_name}/trailer.mp4`);
            return await getDownloadURL(folder_ref);
        }
        catch (error){

        }
        return "";
    }
    static async getMediaElements(folder_name){
        try{
            const urls = [];
            const folder_ref = ref(firebaseStorage, `teatrotierra/${folder_name}`);
            const res = await list(folder_ref, {maxResults: 10});
            for (const itemRef of res.items) {
                const url = await getDownloadURL(itemRef);
                urls.push(
                    url
                );
            }
            return urls;
        }
        catch (error){
            console.log(error);
        }
    }
    static async getBannerImages(play_name){
        try{
            var urls  = [];
            const folder_ref = ref(firebaseStorage, `teatrotierra/${play_name}/Fotos`);
            const res = await list(folder_ref, {maxResults: 10});
            for (const itemRef of res.items) {
                const url = await getDownloadURL(itemRef);
                urls.push(
                    {image:url}
                );
            }
            return urls;
        }
        catch (error){
            console.log(error);
        }
    }

    static async getDocument(playName, collection="obras"){
        const playReference= doc(fireStore, collection, playName);
        const playSnapshot = await getDoc(playReference);
        return playSnapshot.data();
    }
}