import React from 'react';
import '../css/components/footer.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"; // Import the SCSS file
import {faFacebook, faInstagram, faYoutube} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
    return (
        <footer className="footer">
            <div className="container">
                <div className="contact-info">
                    <p>
                        <a href="tel:+573124038145">Telefono: +57 312 403 8145</a>
                        <br/>
                        <a href="tel:+573133930507">Telefono: +57 313 393 0507</a>
                        <br/>
                        <a href="https://wa.me/+573124038145">WhatsApp: +57 312 403 8145</a>
                        <br/>
                        <br/>
                        REPRESENTANTE LEGAL: JUAN CARLOS MOYANO ORTIZ
                        <br/>
                        DIRECCIÓN: CALLE 31 # 4 – 27

                    </p>
                </div>
                <div className="social-media">
                    <a href="https://www.instagram.com/teatrotierra" rel="noreferrer noopener" target={"_blank"}>
                        <FontAwesomeIcon icon={faInstagram} color={"#291100"} size={"2x"}/>
                    </a>
                    <a href="https://www.youtube.com/@GrupoTeatroTierra" rel="noreferrer noopener" target={"_blank"}>
                        <FontAwesomeIcon icon={faYoutube} color={"#291100"} size={"2x"}/>
                    </a>
                    <a href="https://www.facebook.com/teatrotierraperfil" rel="noreferrer noopener" target={"_blank"}>
                        <FontAwesomeIcon icon={faFacebook} color={"#291100"} size={"2x"}/>
                    </a>
                </div>
                <div className="copyright">
                    <p>&copy; {new Date().getFullYear()} Teatro Tierra</p>
                    <p>
                        <a href="mailto:teatrotierra@hotmail.com">teatrotierra@hotmail.com</a>
                    </p>
                    <p>
                        <a href="mailto:teatrotierra.comunicaciones@gmail.com">teatrotierra.comunicaciones@gmail.com</a>
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
