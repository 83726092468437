import React from "react";
import "../css/components/play-card.css";
class PersonItem extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            img: props.img || "",
            nombre: props.nombre || "Nombre",
            cargo: props.cargo || "Cargo"
        }
    }
    render() {

        return (
            <div className={"person-item"}>
                <div className={"content"}>
                    <img
                        src={this.state.img}
                        alt={"person"}/>
                    <h1>
                        {this.state.nombre}
                    </h1>
                    <h4>
                        {this.state.cargo}
                    </h4>
                </div>
            </div>
        );
    }
}

export default PersonItem;