import React, { useState, useEffect } from 'react';
import '../css/routes/playview-page.css';
import Carousel from '../components/carousel';
import {useLoaderData} from "react-router-dom";
import FirebaseService from "../services/firebase-service";

export async function loader({params}){
    const id = params.playId;
    const data = await FirebaseService.getDocument(id);
    const posterURL = await FirebaseService.getPoster(data.raw_name);
    const banners = await FirebaseService.getBannerImages(data.raw_name);
    const trailerUrl = await FirebaseService.getTrailer(data.raw_name);
    return {id, data, posterURL, banners, trailerUrl};
}

const VistaObra = ({ match }) => {
    const {id, data, posterURL, banners, trailerUrl} = useLoaderData();


    useEffect(() => {
        // Optional data fetching logic if needed
    }, []); // Empty dependency array to run only once

    var fichaArtistica = [];

    for(const [key, value] of Object.entries(data.ficha).sort()){
        var lista = [];
        if(typeof value == "object"){
            for(const [_, val] of Object.entries(value)){
                lista.push(
                    <li>
                        {val}
                    </li>
                );
            }
        }
        fichaArtistica.push(
            <div className={"item"}>
                <h4>
                    {key.replace(/[0-9]/g, '')}:
                </h4>
                <p>
                    {lista.length === 0?value:<ul>{lista}</ul>}
                </p>
            </div>
        );
    }

    return (
        <div className="play-view">
            <div className="play-banner-wrapper">
                <div className="play-banner-container">
                    <div className="play-banner-poster">
                        <figure>
                            <img
                                src={posterURL}
                                alt=""
                            />
                        </figure>
                    </div>
                    <div className="play-banner-carousel">
                        <Carousel images={banners} />
                    </div>
                </div>
            </div>
            <div className="play-info">
                <div className="play-datasheet">
                    <div className={"text"}>{fichaArtistica}</div>
                </div>
                <div className={"info"}>
                    <div className="play-synopsis">
                        <div className={"text"}>
                            {data["reseña"]}
                        </div>
                    </div>
                    <br/>
                    {
                        trailerUrl !== "" ?
                        <div className="trailer">
                            <video width={"75%"} controls>
                                <source src={trailerUrl} type="video/mp4"/>
                            </video>
                        </div>
                    :""
                    }
                </div>

            </div>
        </div>
    );
};

export default VistaObra;
