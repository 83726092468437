import {Link, useRouteError} from "react-router-dom";
import "./css/routes/404.css";
import Header from "./components/header";
export default function ErrorPage() {
    let error = useRouteError();
    console.error(error);
    if (error === null) {
        error = "";
    }

    return (
        <div>
            {/* <Header/> */}
            <div id="error-page">
                <h1>Oops!</h1>
                <p>🏗🚧 Lo sentimos, la página está en actualización. 🚧🏗</p>
                <p>🛠 Por favor sigue explorando y mirando nuestras creaciones mientras trabajamos. 🛠</p>

                { error === null ? <div>
                    <h2>Error: </h2>
                    <p><i>{error.statusText || error.message}</i></p>
                </div> : ""}
            </div>
        </div>
    );
}