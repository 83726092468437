import "../css/routes/history-page.css"
import img1 from "../media/historia/circo invisible 1.jpeg"
import img2 from "../media/historia/comparsa cultura ciudadana.jpeg"
import img4 from "../media/historia/circo invisible.jpeg"
import img5 from "../media/historia/historia_img (3).JPG"
import img6 from "../media/historia/IMG-20230828-WA0014.jpg"
import img7 from "../media/historia/IMG_20191112_200330.jpg"
import portada from "../media/historia/portada2.jpg";
import img8 from "../media/historia/portada.jpg";

import temporadas from "../media/historia/Temporadas.pdf"
import festivales from "../media/historia/Festivales.pdf"
import {faFacebook} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {faFilePdf} from "@fortawesome/free-solid-svg-icons";

const HistoryPage = () => {

    // No need for names array as JSX can directly map over objects
    return (
        <div className={"history-page"}>
            <h1>
                Historia del Teatro Tierra
            </h1>
            <ul>
                <li>
                    <img src={portada} alt={""} style={{borderRadius: 10, aspectRatio:"inherit", width:"50%"}}/>
                </li>
                <li>
                    <p>
                        El <h4>Teatro Tierra</h4> nació en 1989 y es creado por Juan Carlos Moyano y Clara Inés Ariza.
                        Desde sus inicios ha logrado crear un camino independiente y un estilo
                        de reconocida calidad profesional.
                    </p>
                </li>
                <li>
                    <img
                        src={img1}/>
                    <img
                        src={img4}/>
                </li>
                <li>
                    <p style={{backgroundColor: "#cbae69"}}>
                        En la poética del <h4>Teatro Tierra</h4> los objetos tienen vida propia y la actuación incluye
                        un trabajo
                        a partir de las relaciones entre el cuerpo, el espacio y los elementos escénicos. Cada montaje
                        es una
                        estructura autónoma donde la puesta en escena condensa la acción dramática, las posibilidades
                        actorales
                        y la poesía de las imágenes plásticas.

                        <br/>
                        <br/>

                        El practica un arte forjado en la brega del escenario, con raíces en lo colombiano y
                        en las herencias del teatro universal. Además de las giras, el grupo ha recorrido las regiones
                        del
                        país, durante años, indagando culturas y anudando raíces.
                    </p>

                </li>
                <li>
                    <img
                        src={img5}/>
                    <p style={{backgroundColor: "#8c712c"}}>
                        Temáticamente, además de la memoria histórica, el grupo se ha nutrido de inagotables fuentes de
                        la literatura y ha elaborado lenguajes teatrales donde las metáforas escritas se transforman en
                        acciones dramáticas e imágenes tangibles de voltaje emotivo. Ha vertido a la escena textos de
                        Dostoievski, Henry Miller, García Márquez, Par Lagerkvist, Sor Juana Inés de la Cruz, René
                        Rebetez, Úrsula K.
                        LeGuin, Aquiles Nazoa, José Saramago, José Eustasio Rivera, Evelio José Rosero, Soledad Acosta
                        de
                        Samper, Fernando Pessoa, Juan Carlos Moyano y otros autores de historias controvertidas.
                        <br/>
                        <br/>
                        El nombre del grupo es un elogio al planeta, a la madre primordial y a los caminos recorridos en
                        el
                        rumbo del arte y de la vida. Los viajes y los intercambios han generado una especie de escuela
                        itinerante de formación y forja, a través de talleres, montajes y encuentros creativos, en
                        distintos
                        lugares de Colombia y Latinoamérica.
                    </p>
                </li>
                <li>
                    <p style={{backgroundColor: "#c79c7a"}}>
                        A lo largo de estos años el Teatro Terra ha creado más de 30 montajes con una calidad escénica y
                        profesionalismo entre los más significativos están:
                        <br/>
                        <br/>
                        Los Ritos del Retorno o Las Trampas de la Fe (1989) /
                        El Enano (1990); Memoria y Olvido de Úrsula Iguarán (1991) /
                        Sexus (1995) /
                        La Vorágine (2008) /
                        La Insurrección de las Hormigas (2011) /
                        La Casa de la Perdición (2011) /
                        La Isla del Tesoro (2012) /
                        La Montaña de los Signos (2012) /
                        La Vida Privada De Las Muñecas De Trapo (2015) /
                        Los Cinco Entierros De Pessoa (2015-2016) /
                        La maldición del rey ciego (2019) /
                        El Evangelio según María (2019) /
                        Lo Inconfesable (2020) /
                        Hécuba o la razón de la locura (2021) /
                        Los deseos de Lisístrata (2021) /
                        La resurrección de los condenados (2021) /
                        Mina//Mata (2023)
                    </p>
                    <img
                        src={img2}/>
                </li>
                <li>
                    <img src={img6} alt={""}/>

                    <p style={{backgroundColor: "#8c6030"}}>
                        Igualmente a lo largo de su experiencia, el <h4>Teatro Tierra</h4> ha participado de diferentes
                        Festivales y Temporadas. Algunos festivales han sido:
                        <br/>
                        <br/>
                        Festival Iberoamericano de Teatro de Bogotá /
                        Festival Internacional de Teatro de Manizales /
                        Festival Internacional de Teatro de Alentejo /
                        Festival Internacional de Artes Performativas en Sintra /
                        Cumbre Mundial de Arte y Cultura Para la Paz de Colombia /
                        Festival Al Aire Puro - Encuentro Internacional de Teatro Callejero /
                        Festival de Teatro de Bogotá /
                        Gala Teatral - Teatro Jorge Eliecer Gaitán /
                        Festival Internacional de Teatro /
                        Embalse Córdoba - Argentina 2008 /
                        Festival Internacional de Teatro Experimental - FITE /
                        Quito - Guayaquil /
                        Ecuador 2008 /
                        Kwachon Madangkuk Festival Seúl /
                        Corea 2000 /
                        Festival Internacional de Teatro Hispano Miami, EU, 1991. /
                    </p>

                </li>
                <li>
                    <img src={img7} alt={""}/>
                    <img src={img8} alt={""}/>

                </li>
            </ul>
            <div className={"links"}>
                <a href={temporadas} target={"_blank"}>
                    Listado de temporadas realizadas <FontAwesomeIcon icon={faFilePdf} color={"#291100"}/>
                </a>
                <br/>
                <a href={festivales} target={"_blank"}>
                    Listado de festivales en los que se ha participado <FontAwesomeIcon icon={faFilePdf} color={"#291100"}/>
                </a>
            </div>
        </div>
    );
};

export default HistoryPage;
