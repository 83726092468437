import React from 'react';
import '../../css/routes/noticias.scss';
import PlayCard from '../../components/play-card';
import {useLoaderData} from "react-router-dom";
import FirebaseService from "../../services/firebase-service";

export async function loader(){
    const plays = await FirebaseService.listCollection("noticias");
    return { plays };
}

const NoticiasPage = () => {
    const { plays } = useLoaderData();
    // No need for names array as JSX can directly map over objects
    return (
        <div className="creaciones-page noticias-page">
            <h1>Noticias</h1>
            <div className="card-container">
                {Object.values(plays.plays).map((play) => {
                    const date = new Date(play.year.seconds*1000);
                    const raw_name = "Noticias/"+play.title.replace(/[\:\"\?\/]/gi, '');
                    return (
                        <PlayCard
                            key={play.title} // Key should be based on unique play identifier
                            title={play.title}
                            title_raw={raw_name}
                            description={" "}
                            year={date.toLocaleDateString("es")}
                            id={play.id}
                        />
                    )
                })}
            </div>
        </div>
    );
};

export default NoticiasPage;
