import React, {useState, useEffect} from 'react';
import trailer from '../../media/talleres/Siete Claves del Teatro  Mágico/trailer 7 claves.mp4';

import '../../css/routes/talleres.css';


import foto1 from '../../media/talleres/Metamorfosis y Vuelo/Foto/IMG_2988.JPG';
import foto2 from '../../media/talleres/Metamorfosis y Vuelo/Foto/IMG_1989.JPG';
import foto4 from '../../media/talleres/Metamorfosis y Vuelo/Foto/IMG_2348.JPG';
import foto5 from '../../media/talleres/Metamorfosis y Vuelo/Foto/IMG_2654.JPG';


import circle1 from '../../media/talleres/Metamorfosis y Vuelo/Foto/IMG_1958.JPG';
import circle2 from '../../media/talleres/Metamorfosis y Vuelo/Foto/IMG_2657.JPG';




import poetica from '../../media/talleres/Metamorfosis y Vuelo/Foto/IMG_2834.JPG';
import fundamentos from '../../media/talleres/Metamorfosis y Vuelo/Foto/IMG_2700.JPG';
import cuerpometafora from '../../media/talleres/Metamorfosis y Vuelo/Foto/IMG_2663.JPG';
import herramientas from '../../media/talleres/Metamorfosis y Vuelo/Foto/IMG_2989.JPG';

const TallerMetamorfosis = () => {


    return (
        <div className="talleres-page">
            <h1 className={"title-ban"}>
                Metamorfosis y vuelo
            </h1>

            <div className={"taller-item"}>
                <img src={circle1} style={{border:"none"}}/>
                <div style={{width: "30px"}}/>
                <p style={{textAlign: "justify", fontSize: "1.5vw", color: "black", backgroundColor: "transparent"}}>
                    Durante casi medio siglo Juan Carlos Moyano, director del Teatro Tierra, ha forjado un camino
                    relevante
                    en el teatro colombiano. Sus construcciones dramatúrgicas y las puestas en escena que ha logrado,
                    permiten apreciar un camino constante de indagaciones acerca de la actuación y los lenguajes
                    escénicos.
                    En este taller de trabajo práctico se transmiten elementos del quehacer de tablas y se comparten
                    saberes
                    específicos del arte dramático.
                </p>
            </div>
            <div className={"taller-item"}>
                <p style={{textAlign: "justify", fontSize: "1.5vw", color:"black", backgroundColor:"transparent"}}>
                    En ocho días de trabajo se tratarán temas que son basamentos de una metodología que permite
                    transitar
                    por una experiencia donde se abordan instrumentos de aplicación directa. De un estado larvario se
                    evoluciona a la transformación de la oruga en criatura alada capaz de emprender el vuelo,
                    abandonando la
                    crisálida, descubriendo los recursos expresivos que potencialmente es posible desarrollar desde el
                    oficio del actor o de la actriz.
                </p>
                <div style={{width: "30px"}}/>
                <img src={circle2} style={{border: "none"}}/>
            </div>

            <h1>
            Los temas del taller son los siguientes:
            </h1>
            <div className={"claves-container"}>
                <div className={"clave-item"} style={{backgroundColor: "#bf9100"}}>
                    <h1>Herramientas pre-expresivas:</h1>
                    <img src={herramientas}/>
                    <p>
                        Se trabaja sobre una serie de ejercicios que permiten afinar los cuerpos, reconocer los
                        bloqueos, mejorar la concentración dinámica, manejar el espacio y fortalecer la presencia en el
                        escenario.
                    </p>
                </div>
                <div className={"clave-item"} style={{backgroundColor: "#eb7b2f"}}>
                    <h1>El Cuerpo Metáfora:</h1>
                    <img src={cuerpometafora}/>
                    <p>
                        Desde la acción física, los organismos son canteras de lo creativo y contienen universos
                        sensibles y emotivos, que pueden verterse en el movimiento, el gesto, la palabra y el silencio.
                    </p>
                </div>
                <div className={"clave-item"} style={{backgroundColor: "#ff9934"}}>
                    <h1>La poética de los objetos:</h1>
                    <img src={poetica}/>
                    <p>
                        Los elementos tienen vida propia y contienen formas sugerentes y usos inesperados que escapan a
                        la lógica literal y permiten descubrir los ángulos ocultos, la polivalencia visual, las
                        musicalidades latentes y la relación interactiva entre sujetos y objetos.
                    </p>
                </div>
                <div className={"clave-item"} style={{backgroundColor: "#7f8000"}}>
                    <h1>Fundamentos de la composición escénica:</h1>
                    <img src={fundamentos}/>
                    <p>
                        La dimensión plástica y la dimensión sonora, la relación interactiva entre sujetos y objetos y
                        el proceso de montaje desde la actuación, la planimetría y la construcción de momentos
                        escénicos.
                    </p>
                </div>
            </div>
            <p style={{textAlign: "center", fontSize: "1.3vw", fontWeight: "bold"}}>
                Es necesario experiencia, breve o extensa, en el escenario, desde el teatro, la danza o el performance.
            </p>
            <div className={"top-images"} style={{height:"200px"}}>
                <img src={foto1}/>
                <img src={foto2}/>
                <img src={foto4}/>
                <img src={foto5}/>
            </div>
        </div>
    );
};

export default TallerMetamorfosis;
