import '../css/routes/director-page.css';
import React from "react";

const DirectorPage = () => {

    return (
        <div className="director-page">
            <div className={"section"}>
                <div className={"info-column"}>
                    <div className={"name"}>
                        <h1>
                            Juan Carlos Moyano
                        </h1>
                        <h3>
                            Director y Dramaturgo
                        </h3>
                    </div>
                    <p>
                        Autor dramático y director. Bogotá 1959. Durante 49 años se ha dedicado al trabajo escénico y
                        como consecuencia ha forjado un camino donde el teatro y la poesía son una actitud de vida.
                        Fundador y director artístico del Teatro Tierra, un grupo estable con mas de tres décadas de existencia.
                        Inicialmente fue Dramaturgo y Actor del Teatro Taller de Colombia y, también, dramaturgo y
                        director del Circo Invisible, entre las décadas del setenta y ochenta. Es uno de los directores
                        y dramaturgos más reconocidos del teatro colombiano. Ha sido pionero en propuestas de vanguardia
                        desde la década de 1970. Ganador de Premio Nacional de Dramaturgia otorgado por el Ministerio de
                        Cultura 2021.
                    </p>
                </div>
                <div className={"director-img"}>
                    <img
                        src={"https://storage.googleapis.com/teatrotierra-50733.appspot.com/teatrotierra/Juan%20Carlos/Fotode%20Juan%20Carlos%20Moyano.jpg"}/>
                </div>
            </div>
            <div className={"section"}>
                <div className={"director-img"} style={{width: "20%"}}>
                    <img
                        src={"https://storage.googleapis.com/teatrotierra-50733.appspot.com/teatrotierra/Juan%20Carlos/321278747_3362300874097569_1295456426568369763_n.jpg"}
                        style={{objectPosition:"left"}}/>
                </div>
                <div className={"info-column"}>

                <p>
                        Ha viajado por casi toda Colombia, por Suramérica, Centroamérica, México y Estados Unidos, Cuba,
                        República Dominicana, Noruega, Suecia, Dinamarca, Francia, Italia, España, Portugal y Corea,
                        haciendo teatro y transmitiendo saberes a través de laboratorios y talleres no formales,
                        directos, tradicionales. Ha sido discípulo de hombres como Eugenio Barba y Peter Shumann,
                        directores del Odin Teatret y del Bread and Puppet; así como de Santiago García, Enrique
                        Buenaventura y Gilberto Martínez, maestros mayores del teatro colombiano.
                    </p>
                </div>
            </div>
            <div className={"section"}>
                <div className={"info-column"}>
                    <p>
                        Asimismo, ha dirigido obras con otras agrupaciones teatrales y ha participado en experiencias
                        pedagógicas de teatro y circo. Es Escritor y es ante todo un poeta y soñador del escenario.
                        <br/>
                        <br/>
                        Ha publicado los siguientes libros: Palabra y Herejia (Ensayos), Espectros (poemas), La pasión de
                        las lunas (relatos), En la línea beduina (relatos), Guía de sonámbulos (relatos), Punto de fuga
                        (novela), Con los pájaros en la cabeza (novela), Tigre comedor de sable y otros cuentos
                        (cuentos) y Cinco textos dramáticos (dramaturgia). Varios de sus textos dramáticos están
                        publicados en la colección Grandes creadores del teatro colombiano del Ministerio de Cultura
                        bajo el nombre Arte de labranza. Ha sido ganador de varios premios por sus textos dramáticos y
                        literarios.
                    </p>
                </div>
                <div className={"director-img"} style={{width:"20%"}}>
                    <img
                        src={"https://storage.googleapis.com/teatrotierra-50733.appspot.com/teatrotierra/Juan%20Carlos/2X8A0169.JPG"}
                        style={{objectPosition:"right"}}
                    />
                </div>
            </div>
            <br/>
            <br/>
            <div className={"section"}>
                <video width={"60%"} controls style={{margin: "0 auto", borderRadius: "10px"}} controls="controls" preload="none" onclick="this.play()">
                    <source
                        src={"https://storage.googleapis.com/teatrotierra-50733.appspot.com/teatrotierra/Juan%20Carlos/Moyanito_1.mp4"}
                        type="video/mp4"/>
                </video>
            </div>
            <br/>
            <br/>
        </div>
    );
}

export default DirectorPage;
