import React from "react";
import "../css/components/play-card.css";
import {Link} from "react-router-dom";
import FirebaseService from "../services/firebase-service";
class PlayCard extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            image_url: "",
            title: props.title || "Titulo",
            title_raw: props.title_raw || "Titulo",
            description: props.description || "Resumen, lorem ipsum dolor sit amet",
            // year: props.year || 1984,
            available: props.available || false,
            id: props.id
        }
    }
    componentDidMount() {
        try{
            console.log(this.state.title_raw)
            FirebaseService.getPoster(this.state.title_raw).then(
                (url)=>{
                    this.setState({
                        image_url: url
                    })
                }
            );
        }
        catch (error){

        }

    }

    render() {
        const currentUrl = window.location.href
        return(
            <div className={"play-card-item"}>
                {
                currentUrl.includes("/Noticias") || currentUrl.includes("/creaciones") ?
                <Link to={"./" + this.state.id} className={"more-button"}>
                    <div className={"play-photo"}>
                        <img src={this.state.image_url} alt={""}/>
                    </div>
                    <div className={"play-content"}>
                        <h3>
                            {this.state.title}
                        </h3>
                        {/* <h3 id={"date"}>
                            {this.state.year}
                        </h3> */}
                        <p>
                            {this.state.description}
                        </p>
                    </div>
                </Link>
                : <Link to={"./Noticias/" + this.state.id} className={"more-button"}>
                    <div className={"play-photo"}>
                        <img src={this.state.image_url} alt={""}/>
                    </div>
                    <div className={"play-content"}>
                        <h3>
                            {this.state.title}
                        </h3>
                        <h3 id={"date"}>
                            {this.state.year}
                        </h3>
                        <p>
                            {this.state.description}
                        </p>
                    </div>
                </Link>}
            </div>
        );
    }
}

export default PlayCard;
