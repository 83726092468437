import React, { useState, useEffect } from "react";
import "../../css/routes/calendarview-page.css";
import { useLoaderData } from "react-router-dom";
import FirebaseService from "../../services/firebase-service";

export async function loader({ params }) {
  const id = params.newId;
  const data = await FirebaseService.getDocument(id, "calendario");
  // const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
  // const name = month[data.mes];
  // const raw_name = "Calendario/" + name.replace(/[\:\"\?\/]/gi, "");
  const raw_name = "Calendario/"+ data.title.replace(/[\:\"\?\/]/gi, '');
  const poster = await FirebaseService.getPoster(raw_name);
  const mediaList = await FirebaseService.getMediaElements(raw_name);
  return { id, data, poster, mediaList };
}

const VistaEvento = ({ match }) => {
  const { id, data, poster, mediaList } = useLoaderData();

  const [imgs, setImgs] = useState([]);
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    const types = new Map([
      ["png", "img"],
      ["jpg", "img"],
      ["jpeg", "img"],
      ["gif", "img"],
      ["mp4", "video"],
      ["3gp", "video"],
    ]);

    var mediaVideo = mediaList.filter((filename) => {
      const url = new URL(filename);
      const extension_slice = url.pathname.split(".");
      const extension = extension_slice[extension_slice.length - 1];
      const type = types.get(extension.toLowerCase());

      return type === "video";
    });
    var mediaImages = mediaList.filter((filename) => {
      const url = new URL(filename);
      const extension_slice = url.pathname.split(".");
      const extension = extension_slice[extension_slice.length - 1];
      const type = types.get(extension.toLowerCase());
      const name = extension_slice[extension_slice.length - 2];
      return (
        type === "img" && name.slice(name.length - 7, name.length) !== "Portada"
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps

    var tempArr = mediaVideo.map((element) => {
      return <video src={element} controls></video>;
    });
    setVideos(tempArr);
    var tempArr = mediaImages.map((element) => {
      return <img src={element} />;
    });
    setImgs(tempArr);
  }, []); // Empty dependency array to run only once

  // const date = new Date(data.year.seconds*1000);

  return (
    <div className="calendar-view">
      <h1>{data.title}</h1>
      <div className="calendar-header">
        <div className="calendar-image">
          <img src={poster} style={{ objectFit: "contain" }} alt="" />
        </div>
        {/* <figure>
        <img src={poster} style={{ objectFit: "contain" }} alt="" />
        </figure> */}
        <div className={"information"}>
          <h2>Información general</h2>
          <div className={""}>
            <p> Lugar: {data.espacio}</p>
            <p> Ciudad: {data.ubicacion} </p>
            <p> Fecha: {data.fecha_inicial} </p>
            <p> Hora: {data.hora_inicial} </p>
            <p> Dirección: {data.direccion} </p>
          </div>
          <div className={"boton-boletas"}>
            <a alt="" href="https://wa.me/+573133930507">
              {" "}
              Reservas e información{" "}
            </a>
          </div>
        </div>
      </div>
      <div className="calendar-content">
        <p className={"text"}></p>
        <div className={"media-content"}>
          {videos.length === 0 ? imgs.slice(0, imgs.length / 2) : videos}
        </div>
        <div className={"media-content"}>
          {videos.length === 0
            ? imgs.slice(imgs.length / 2, imgs.length)
            : imgs}
        </div>
      </div>
    </div>
  );
};

export default VistaEvento;