import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import {
    createBrowserRouter,
    RouterProvider
} from "react-router-dom";
import Root from "./routes/root";
import ErrorPage from "./error-page";
import CreacionesPage, {loader as creacionesLoader}from "./routes/creaciones-page";
import VistaObra, {loader as viewLoader} from "./routes/playview-page";
import LandPage, {loader as landPageLoader} from "./routes/land-page";
import NosotrosPage from "./routes/nosotros-page";
import GrupoPage from "./routes/grupo-page";
import DirectorPage from "./routes/director-page";
import HistoryPage from "./routes/historia";
import Historia from "./routes/historia";
import InstitucionalPage from "./routes/intitucional-page";
import PublicacionesPage from "./routes/publicaciones-page";
import SedePage from "./routes/sede-page";
import Talleres from "./routes/talleres/talleres";
import TalleresPage from "./routes/talleres/talleres";
import TallerSieteClaves from "./routes/talleres/taller-siete-claves";
import TallerMetamorfosis from "./routes/talleres/taller-metamorfosis";
import NoticiasPage, {loader as noticiasLoader} from "./routes/noticias/noticias-page";
import VistaNoticia, {loader as noticiasViewLoader} from "./routes/noticias/newsview-page";
import CalendarioPage, {loader as calendarioLoader} from "./routes/calendario/calendario-page";
import VistaEvento, {loader as calendarViewLoader} from "./routes/calendario/calendarview-page";


const router = createBrowserRouter([
    {
        path: "/",
        element: <Root/>,
        errorElement: <ErrorPage/>,
        children: [
            {
              path: "/",
              element: <LandPage/>,
              loader: landPageLoader
            },
            {
                path: "/creaciones",
                element: <CreacionesPage/>,
                loader: creacionesLoader
            },
            {
                path: "/creaciones/:playId",
                element: <VistaObra/>,
                loader: viewLoader
            },
            {
                path: "/grupo/",
                element: <GrupoPage/>
            },
            {
                path: "/director/",
                element: <DirectorPage/>
            },
            {
                path: "/Historia/",
                element: <HistoryPage/>
            },
            {
                path: "/Institucional/",
                element: <InstitucionalPage/>
            },
            {
                path: "/Publicaciones/",
                element: <PublicacionesPage/>
            },
            {
                path: "/Sede/",
                element: <SedePage/>
            },
            {
                path: "/talleres/",
                element: <TalleresPage/>
            },
            {
                path: "/taller-siete-claves/",
                element: <TallerSieteClaves/>
            },
            {
                path: "/taller-metamorfosis/",
                element: <TallerMetamorfosis/>
            },
            {
                path: "/Noticias/",
                element: <NoticiasPage/>,
                loader: noticiasLoader
            },
            {
                path: "/Noticias/:newId",
                element: <VistaNoticia/>,
                loader: noticiasViewLoader
            },
            {
                path: "/Calendario/",
                element: <CalendarioPage/>,
                loader: calendarioLoader
            },
            {
                path: "/Calendario/:newId",
                element: <VistaEvento/>,
                loader: calendarViewLoader
            },
            {
                path: "/memoria-y-comunidad/",
                element: <ErrorPage/>
            },
            {
                path: "/procesos-creacion/",
                element: <ErrorPage/>
            },
        ]
    }
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router}/>
  </React.StrictMode>
);
