import {Outlet, ScrollRestoration, useLoaderData} from "react-router-dom";
import React, {useEffect, useState} from "react";
import PageHeader from "../components/header";
import "../css/main.css";
import Footer from "../components/footer";
import InfoPopup from "../components/info-popup";

export default function Root() {

    const [openModal, setOpenModal] = useState(!true);
    useEffect(() => {

    }, []);

    return (
        <React.Fragment>
            <PageHeader/>
            <div id="detail">
                <Outlet/>
            </div>
            <Footer/>
            <ScrollRestoration/>
            {openModal ? <InfoPopup toggle={()=>{setOpenModal(false)}}/> : null}
        </React.Fragment>
    );
}
