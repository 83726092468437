import "../css/components/modal.css"
import React, { Component } from "react";
import frontBanner from "../media/Front Banner.jpg"
export default class InfoPopup extends Component {
    handleClick = () => {
        this.props.toggle();
    };
    render() {
        return (
            <div className="modal">
                <div className="modal-content">
                    <span className="close" onClick={this.handleClick}>&times;</span>
                    <img src={frontBanner} alt={"Front Banner"}/>
                </div>
            </div>
        );
    }
}
