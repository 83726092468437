import React from "react";
import NavBar from "./nav-bar";
import Logo from "../media/Logo.png"
import "../css/header.css";
import {Link} from "react-router-dom";
import FirebaseService from "../services/firebase-service";
class PageHeader extends React.Component{

    render() {
        return (
            <div className={"header"}>
                <Link to={"/"}>
                    <img src={Logo} alt={"TeatroTierraLogo"}/>
                </Link>
                <NavBar/>
            </div>
        );
    }
}

export default PageHeader;