import React, { useState, useEffect } from 'react';
import '../css/routes/creaciones.css';
import PlayCard from '../components/play-card';
import {useLoaderData} from "react-router-dom";
import FirebaseService from "../services/firebase-service";

export async function loader(){
    const plays = await FirebaseService.listCollection("obras");
    return { plays };
}

const CreacionesPage = () => {

    const { plays } = useLoaderData();
    const vigentes = plays.plays.filter((play)=>{
        return play.current
    })
    const noVigentes = plays.plays.filter((play)=>{
        return !play.current
    })
    // No need for names array as JSX can directly map over objects
    return (
        <div className="creaciones-page">
            <h1>
                Obras en Repertorio
            </h1>
            <div className="card-container">
                {Object.values(vigentes).map((play) => {
                    return (
                        <PlayCard
                            key={play.play} // Key should be based on unique play identifier
                            title={play.play}
                            title_raw={play.raw_name}
                            description={play.initial_text}
                            year={play.year}
                            id={play.id}
                        />
                    );
                })}
            </div>
            <h1>
                Obras de la Memoria
            </h1>
            <div className="card-container">
                {Object.values(noVigentes).map((play) => {
                    return (
                        <PlayCard
                            key={play.play} // Key should be based on unique play identifier
                            title={play.play}
                            title_raw={play.raw_name}
                            description={play.initial_text}
                            year={play.year}
                            id={play.id}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default CreacionesPage;
