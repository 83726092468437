import React from "react";
import {Link} from "react-router-dom";
import Politicas from "../media/institucional/Política de tratamiento y protección de datos personales.pdf";
import Estados2022 from "../media/institucional/Estados Financieros Año 2022.pdf";
class NavBar extends React.Component{
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {

        return (
            <React.Fragment>
                <ul className={"nav-bar"}>
                    <li className={"icon"}><i className="fa fa-bars"></i></li>
                    <Link to={"./creaciones"}>
                        <li>
                            <h1>Creaciones</h1>
                        </li>
                    </Link>
                    <li className="dropdown">
                        <h1 className="dropbtn">
                            Nosotros
                            <i className="fa fa-caret-down"/>
                        </h1>
                        <div className="dropdown-content">
                            <Link to={"./Grupo"}>Grupo</Link>
                            <Link to={"./Historia"}>Historia</Link>
                            <Link to={"./Sede"}>Sede</Link>
                            <Link to={"./Publicaciones"}>Publicaciones</Link>
                            <Link to={"./institucional"}>Institucional</Link>
                        </div>
                    </li>
                    <Link to={"./director"}>
                        <li>
                            <h1>
                                Director
                            </h1>
                        </li>
                    </Link>
                    <Link to={"./Noticias"}>
                        <li>
                            <h1>
                                Noticias
                            </h1>
                        </li>
                    </Link>
                    <Link to={"./Calendario"}>
                        <li>
                            <h1>
                                Programacion
                            </h1>
                        </li>
                    </Link>
                    <li className="dropdown">
                        <h1 className="dropbtn">
                            Escuela Itinerante
                            <i className="fa fa-caret-down"/>
                        </h1>
                        <div className="dropdown-content">
                            <Link to={"./talleres"}>Talleres teatrales</Link>
                            <Link to={"./memoria-y-comunidad"}>Memoria y Comunidad</Link>
                            <Link to={"./procesos-creacion"}>Procesos de Creación</Link>

                        </div>
                    </li>
                </ul>
            </React.Fragment>
        );
    }
}

export default NavBar;
