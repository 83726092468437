import "../css/routes/institucional-page.css"
import Politicas from "../media/institucional/Política de tratamiento y protección de datos personales.pdf"
import Estados2022 from "../media/institucional/Estados Financieros Año 2022.pdf"
import ProgramaEtica from "../media/institucional/Programa de Transparencia y Etica Empresarial.pdf"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilePdf} from "@fortawesome/free-solid-svg-icons";
import React from "react";
const InstitucionalPage = () => {

    return (
        <div className={"institucional-page"}>
            <h1>
                CÓDIGO DE ETICA Y BUEN GOBIERNO
            </h1>
            <p>
                Nuestro código de ética y buen gobierno pretende orientar nuestros comportamientos hacia las reglas que
                rigen las relaciones con nuestro entorno. Somos conscientes de la responsabilidad social basada en la
                creencia de que la sostenibilidad de la empresa en el tiempo impacta positivamente el desarrollo y
                crecimiento de quienes trabajamos día a día en las tablas, nuestras familias, compañeros de teatro,
                proveedores, socios y aliados estratégicos.
                <br/>
                <br/>
                Nos caracterizamos por ofrecer obras de calidad escénica y prfesionales, respetando y esforzándonos por
                garantizar la sana convivencia, el buen teatro, generando momentos de buenas experiencias y esperando
                que esto se refleje en relaciones a largo plazo, lealtad y fidelización a nuestra marca.
                <br/>
                <br/>
                Este es un canal dispuesto para que nuestros espectadores, usuarios, actores, actrices, proveedores,
                contratistas y demás partes interesadas, puedan denunciar de manera segura, anónima y confidencial,
                potenciales situaciones que denoten conductas relacionadas con fraude, corrupción, malas prácticas,
                lavado de activos y cualquier otra situación irregular que vaya en contra de nuestro Código de Ética y
                Buen Gobierno.

            </p>

            <div className={"inst-container"}>
                <a href={ProgramaEtica} target={"_blank"}>
                    <div className={"item"}>
                        <img src={"https://img.freepik.com/vector-gratis/concepto-etica-empresarial_23-2148735369.jpg?w=1380&t=st=1710793914~exp=1710794514~hmac=607e5329af17d52976023538a7952836954d4ba3e9ff883c06429b0856edfd82"}
                             alt={""}/>
                        <h4>
                            Programa de Transparencia y Ética Empresarial -Entidad Simplificada – PTEE–S
                        </h4>
                    </div>
                </a>
                <a href={"https://forms.gle/RRFvdMtfR5Np8v8CA"} target={"_blank"}>
                    <div className={"item"}>
                        <img
                            src={"https://img.freepik.com/vector-premium/ilustracion-formulario-reclamo_126283-578.jpg"}
                            alt={""}/>
                        <h4>
                            Formulario de denuncias anónimas
                        </h4>
                    </div>
                </a>
            </div>

            <h1>
                Estados financieros
            </h1>

            <div className={"inst-container"}>
                <a href={Estados2022} target={"_blank"}>
                    <div className={"item"}>
                        <img
                            src={"https://contadoresculiacan.com.mx/wp-content/uploads/2022/04/%C2%BFPara-que-sirve-un-estado-financiero_.jpg"}
                            alt={""}/>
                        <h4>
                            Descargar estados financieros 2022 <FontAwesomeIcon icon={faFilePdf} color={"#291100"}/>
                        </h4>
                    </div>
                </a>
                <a href={""}>
                    <div className={"item"}>
                        <img
                            src={"https://contadoresculiacan.com.mx/wp-content/uploads/2022/04/%C2%BFPara-que-sirve-un-estado-financiero_.jpg"}
                            alt={""}/>
                        <h4>
                            Descargar estados financieros 2023 <FontAwesomeIcon icon={faFilePdf} color={"#291100"}/>
                        </h4>
                    </div>
                </a>

            </div>


            <h1>
            Política de tratamiento y protección de datos personales
            </h1>
            <div className={"inst-container"}>
                <a href={Politicas} target={"_blank"}>
                    <div className={"item"}>
                        <img src={"https://blog.ida.cl/wp-content/uploads/sites/5/2019/06/PoliticasDatos-655x470.png"}
                             alt={""}/>
                        <h4>
                            Política de tratamiento y protección de datos personales <FontAwesomeIcon icon={faFilePdf}
                                                                                                      color={"#291100"}/>
                        </h4>
                    </div>
                </a>
            </div>
        </div>
    );
};

export default InstitucionalPage;
