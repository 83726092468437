import React from "react";
import "../css/components/play-cardEvents.css";
import { Link } from "react-router-dom";
import FirebaseService from "../services/firebase-service";

class PlayCardEvents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image_url: "",
      title: props.title || "Titulo",
      title_raw: props.title_raw || "Titulo",
      description: props.description || "Resumen, lorem ipsum dolor sit amet",
      direccion: props.direccion || "Por definir",
      espacio: props.espacio || "Por definir",
      ubicacion: props.ubicacion || "Por definir",
      fecha_final: props.fecha_final || "Por definir",
      fecha_inicial: props.fecha_inicial || "Por definir",
      hora_final: props.hora_final || "Por definir",
      hora_inicial: props.hora_inicial || "Por definir",
      mes: props.mes || "Por definir",
      year: props.year || 1984,
      available: props.available || false,
      id: props.id,
    };
  }
  componentDidMount() {
    try {
      FirebaseService.getPoster(this.state.title_raw).then((url) => {
        this.setState({
          image_url: url,
        });
      });
    } catch (error) {}
  }

  render() {
    const currentUrl = window.location.href;
    return (
      <div className={"play-card-itemEvents"}>
        {currentUrl.includes("/Calendario") ? (
          <Link to={"./" + this.state.id} className={"more-button"}>
            <div className={"play-photo"}>
              <img src={this.state.image_url} alt={""} />
              <p>{this.state.image_url}</p>
            </div>
            <div className={"play-content"}>
              <h3>{this.state.title}</h3>
              <p><b>Fecha inicio: </b> {this.state.fecha_inicial}</p>
              <p><b>Fecha fin: </b> {this.state.fecha_final}</p>
              <p><b>Lugar: </b> {this.state.espacio}</p>
              <p><b>Direccion: </b> {this.state.direccion}, {this.state.ubicacion}</p>
            </div>
          </Link>
        ) : (
          <Link to={"./Calendario/" + this.state.id} className={"more-button"}>
            <div className={"play-photo"}>
              <img src={this.state.image_url} alt={""} />
            </div>
            <div className={"play-content"}>
              <h3>{this.state.title}</h3>
              <p>fecha final: {this.state.fecha_final}</p>
              <p>fecha inicial: {this.state.fecha_inicial}</p>
              <p>direccion: {this.state.direccion}</p>
              <p>Lugar: {this.state.espacio}</p>
              <p>ubicacion: {this.state.ubicacion}</p>
            </div>
          </Link>
        )}
      </div>
    );
  }
}

export default PlayCardEvents;
